import axios from "../axios";

export const getShowroomDetail = async slug => {
  const { data } = await axios.get(`/v1/marketing/showrooms/${slug}.json`);

  return data.showroom;
};

export const getShowroomListing = async query => {
  try {
    const { data } = await axios.get(`/v1/marketing/showrooms.json`);
    return data.showrooms;
  } catch (error) {
    return [];
  }
};
