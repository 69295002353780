import { COIN_TRANSACTIONS_GET_SUCCESS } from "../constants/actionTypes";

const initialState = {
  coin_transactions: [],
  coin: {
    balance: null,
  },
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case COIN_TRANSACTIONS_GET_SUCCESS:
      return {
        ...state,
        coin_transactions: action.transactions,
        coin: action.coin,
      };

    default:
      return state;
  }
};

export default walletReducer;
