import {
  REQUEST_PRODUCT,
  RECEIVE_PRODUCT,
  RECEIVE_PRODUCT_V1,
  PRODUCT_DETAIL_CLEANUP,
  UPDATE_PRODUCT_STATE,
  PRODUCT_DETAIL_GET_REQUEST,
  PRODUCT_DETAIL_GET_SUCCESS,
  PREPARE_PRODUCT_DETAIL_STATE,
} from "../constants/actionTypes";

const initialState = {
  products: [],
  productDetail: {},
  isLoadingProductDetail: false,
  is404ProductDetail: false,
  clickedProduct: {},
  relatedProducts: { total: 0, products: [], ads: [] },
  relatedCate2Products: { total: 0, products: [], ads: [] },
  relatedSearchKeywords: [],
  reviewData: {},
};

const transformProductToProductDetail = product => ({
  id: product.id,
  id_number: product.product_id,
  title: product.title,
  image: {
    id: "preload-image",
    src: product.image_thumb,
  },
  images: [{ id: "preload-image", src: product.image_thumb }],
  prices: product.prices,
  shop: {
    id: product.shop_id,
    slug: product.shop_id,
    mall: product.shop?.mall,
  },
  unit_name: product.unit_name,
  categories: { lv1: {}, lv2: {} },
  handle: `${product.slug}-${product.product_id}.html`,
  is_classifieds: product.is_classifieds,
  classifieds: product.classifieds || {},
});

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCT:
      return { ...state, product: action.product };

    case PRODUCT_DETAIL_GET_REQUEST:
      return {
        ...state,
        productDetail: state.clickedProduct,
        is404ProductDetail: false,
        isLoadingProductDetail: true,
        clickedProduct: {},
      };
    case PRODUCT_DETAIL_GET_SUCCESS:
      return {
        ...state,
        productDetail: action.payload.productDetail,
        is404ProductDetail: action.payload.is404,
        isLoadingProductDetail: false,
      };
    case PREPARE_PRODUCT_DETAIL_STATE:
      return {
        ...state,

        clickedProduct: transformProductToProductDetail(action.product),
      };
    case UPDATE_PRODUCT_STATE:
      return { ...state, [action.field]: action.value };
    // CLEANUP
    case PRODUCT_DETAIL_CLEANUP:
      return { ...state, productDetail: {} };

    default:
      return state;
  }
};

export default productsReducer;
