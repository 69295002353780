import {
  RECEIVE_CUSTOMER,
  UPDATE_CUSTOMER_ADDRESS,
  SET_CUSTOMER_ADDRESS_DEFAULT,
  ADD_NEW_CUSTOMER_ADDRESS
} from "../constants/actionTypes";

const initialState = {
  customer: {}
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_CUSTOMER:
      return { ...state, customer: action.customer };

    case UPDATE_CUSTOMER_ADDRESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          addresses: state.customer.addresses.map(address =>
            address.id !== action.address.id ? address : action.address
          ),
          default_address:
            state.customer.addresses.filter(address => address.default) ||
            state.customer.default_address
        }
      };

    case SET_CUSTOMER_ADDRESS_DEFAULT:
      return {
        ...state,
        customer: {
          ...state.customer,
          addresses: state.customer.addresses.map(address => {
            if (address.default && address.id !== action.address.id) {
              return { ...address, default: false };
            }
            return address.id === action.address.id ? action.address : address;
          }),
          default_address: action.address
        }
      };

    case ADD_NEW_CUSTOMER_ADDRESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          addresses: [...state.customer.addresses, action.address]
        }
      };

    default:
      return state;
  }
};

export default customerReducer;
