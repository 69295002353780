export const getPriceByQuantity = (prices, quantity) => {
  try {
    for (const price of prices) {
      if (quantity <= price.end_quantity && quantity >= price.start_quantity) {
        return price.price;
      }
    }
    return prices[0].price;
  } catch (error) {
    return prices[0].price || 0;
  }
};
