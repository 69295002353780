export const sendWebViewEvent = (type, data) => {
  try {
    if (typeof window !== "undefined" && window.ReactNativeWebView) {
      const stringified = JSON.stringify({ type, data });
      window.ReactNativeWebView?.postMessage(stringified);
    }
  } catch (error) {}
};

export const WebviewEvents = {
  AuthChange: "AuthChange",
  LogoutSuccess: "LogoutSuccess",
  HapticFeedback: "HapticFeedback",
  Share: "Share",
  LoginSuccess: "LoginSuccess",
  URLChanged: "URLChanged",
  PageTitle: "PageTitle",
  Navigate: "Navigate",
  Replace: "Replace",
  Push: "Push",
  OpenURL: "OpenURL",
  PopToTop: "PopToTop",
  OpenImageViewer: "OpenImageViewer",
  OpenLoginModal: "OpenLoginModal",
  BlockShop: "BlockShop",
  Goback: "Goback",
  SetLastViewedCategories: "SetLastViewedCategories",
  ViewProduct: "ViewProduct",
  LogEvent: "LogEvent",
  LogEventFB: "LogEventFB",
  RateApp: "RateApp",
  MakeCall: "MakeCall",
};

export const FBAppEvents = {
  AchievedLevel: "fb_mobile_level_achieved",
  AdClick: "AdClick",
  AdImpression: "AdImpression",
  AddedPaymentInfo: "fb_mobile_add_payment_info",
  AddedToCart: "fb_mobile_add_to_cart",
  AddedToWishlist: "fb_mobile_add_to_wishlist",
  CompletedRegistration: "fb_mobile_complete_registration",
  CompletedTutorial: "fb_mobile_tutorial_completion",
  Contact: "Contact",
  CustomizeProduct: "CustomizeProduct",
  Donate: "Donate",
  FindLocation: "FindLocation",
  InitiatedCheckout: "fb_mobile_initiated_checkout",
  Purchased: "fb_mobile_purchase",
  Rated: "fb_mobile_rate",
  Schedule: "Schedule",
  Searched: "fb_mobile_search",
  SpentCredits: "fb_mobile_spent_credits",
  StartTrial: "StartTrial",
  SubmitApplication: "SubmitApplication",
  Subscribe: "Subscribe",
  UnlockedAchievement: "fb_mobile_achievement_unlocked",
  ViewedContent: "fb_mobile_content_view",
  CustomEventViewPhoneNumber: "view_phone_number",
};

export const FBAppEventsParams = {
  AddType: "ad_type",
  Content: "fb_content",
  ContentID: "fb_content_id",
  ContentType: "fb_content_type",
  Currency: "fb_currency",
  Description: "fb_description",
  Level: "fb_level",
  MaxRatingValue: "fb_max_rating_value",
  NumItems: "fb_num_items",
  OrderId: "fb_order_id",
  PaymentInfoAvailable: "fb_payment_info_available",
  RegistrationMethod: "fb_registration_method",
  SearchString: "fb_search_string",
  Success: "fb_success",
  ValueNo: "0",
  ValueYes: "1",
};
