import { useEffect } from "react";
import { googleLoginWithIdToken, setAuthData } from "../../api/Auth";
import { sendWebViewEvent } from "../../helpers/webview";
import { useSelector } from "react-redux";
import {
  logCustomEvent,
  registerSuccessEvent,
} from "../../api/Analytics/enhancedEcommerce";

const GoogleSignIn = () => {
  const mobileAppVersion = useSelector(({ common }) => common.mobileAppVersion);
  const isLoggedIn = useSelector(({ user }) => Boolean(user.user_id));

  useEffect(() => {
    if (!isLoggedIn) {
      setTimeout(() => {
        oneTap();
      }, 1000);
    }
  }, []);

  const oneTap = () => {
    if (typeof window === "undefined" || !window) return;

    const { google } = window;
    if (google && google.accounts) {
      google.accounts.id.initialize({
        client_id: process.env.GOOGLE_OAUTH_CLIENT_ID,
        use_fedcm_for_prompt: true,

        callback: async response => {
          call(response);
        },
      });

      google.accounts.id.prompt();
    }
  };

  const call = async resp => {
    if (resp.credential) {
      const res = await googleLoginWithIdToken(resp.credential);
      if (res.data?.token) {
        const { refresh_token, access_token, sub } = res.data.token;
        if (access_token && refresh_token) {
          try {
            setAuthData({
              authToken: access_token,
              refreshToken: refresh_token,
              userId: sub,
            });

            sendWebViewEvent("AuthChange", {
              accessToken: access_token,
              refreshToken: refresh_token,
              nextPath: "/",
            });

            // facebook pixel event
            if (typeof window.fbq === "function") {
              window.fbq(
                "track",
                "CompleteRegistration",
                {},
                { eventID: `user:${sub}` }
              );
            }

            // google analytic event
            registerSuccessEvent();
            logCustomEvent(mobileAppVersion, {
              event: "sign_up",
            });

            window.location.reload();
          } catch (error) {
            // There was an error on the native side
          }
        }
      }
    }
  };

  return null;
};

export default GoogleSignIn;
