import {
  NEW_ORDER_INITIALIZE,
  EDIT_NEW_ORDER,
  CART_CLEAN,
  RECEIVE_ORDER,
  EDIT_PROMOS,
  NEW_ORDER_CLEANUP,
} from "../constants/actionTypes";

const initialState = {
  newOrders: {},
  order: {},
  promos: {
    f_s_30k: true,
    free_shipping: false,
    free_shipping_prices: 0,
  },
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_ORDER_INITIALIZE:
      return {
        ...state,
        newOrders: {
          ...state.newOrders,
          [action.newOrder.shop_id]: action.newOrder,
        },
      };

    case EDIT_NEW_ORDER:
      if (action.shop_id === "ALL") {
        const _newOrders = { ...state.newOrders };
        Object.keys(_newOrders).forEach(shop_id => {
          _newOrders[shop_id][action.field] = action.value;
        });

        return { ...state, newOrders: _newOrders };
      }
      return {
        ...state,

        newOrders: {
          ...state.newOrders,
          [action.shop_id]: {
            ...state.newOrders[action.shop_id],
            [action.field]: action.value,
          },
        },
      };

    case EDIT_PROMOS:
      return {
        ...state,
        promos: { ...state.promos, [action.field]: action.value },
      };

    case RECEIVE_ORDER:
      return { ...state, order: action.order };

    case NEW_ORDER_CLEANUP:
      return { ...state, newOrders: {} };

    default:
      return state;
  }
};

export default ordersReducer;
