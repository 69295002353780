import {
  PREPARE_PRODUCT_DETAIL_STATE,
  PRODUCT_DETAIL_CLEANUP,
  PRODUCT_DETAIL_GET_REQUEST,
  PRODUCT_DETAIL_GET_SUCCESS,
  RECEIVE_PRODUCT,
  RECEIVE_PRODUCT_V1,
  UPDATE_PRODUCT_STATE,
} from "../constants/actionTypes";

export const addProduct = product => ({
  type: RECEIVE_PRODUCT,
  product,
});

export const addProductV1 = product => ({
  type: RECEIVE_PRODUCT_V1,
  product,
});

export const productDetailGetRequest = (productId, isSeBot = false) => ({
  type: PRODUCT_DETAIL_GET_REQUEST,
  productId,
  isSeBot,
});

export const productDetailGetSuccess = (
  params = { is404: false, productDetail: {} }
) => ({
  type: PRODUCT_DETAIL_GET_SUCCESS,
  payload: params,
});
export const updateProductState = (field, value) => ({
  type: UPDATE_PRODUCT_STATE,
  field,
  value,
});

export const prepareProductDetailState = product => ({
  type: PREPARE_PRODUCT_DETAIL_STATE,
  product,
});
