import axios from "axios";
import { refreshToken } from "./Auth";

import Cookies from "universal-cookie";
import { sendWebViewEvent } from "../helpers/webview";
import { baseCookieConfig } from "../helpers/storage/cookie";

const instance = axios.create({
  baseURL: process.env.SITE_URL + `/endpoint`,
  validateStatus: function (status) {
    return status >= 200 && status < 400; // default
  },
});

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = error => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;
    if (
      originalRequest?.url ===
      process.env.SITE_URL + `/endpoint` + `/v1/user/api/auth`
    ) {
      // login failed
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.status === 503 &&
      error.response?.headers?.["x-fw"] === "true"
    ) {
      if (typeof window !== "undefined") {
        window.location.reload();
        return Promise.reject(error);
      }
    }

    if (
      error.response &&
      error.response.status === 401 &&
      typeof window !== "undefined" &&
      !originalRequest._retry
    ) {
      const cookie = new Cookies();
      const authToken = cookie.get("authToken");
      if (authToken) {
        // expired token

        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then(() => {
              return instance(originalRequest);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        const rf = cookie.get("refreshToken");

        if (rf) {
          return new Promise(function (resolve, reject) {
            refreshToken(rf)
              .then(data => {
                if (data.access_token) {
                  cookie.set("authToken", data.access_token, {
                    ...baseCookieConfig,
                    expires: new Date(Date.now() + 157784760000),
                  });
                  localStorage.setItem("ts_user", data.sub);
                  sendWebViewEvent("AuthChange", {
                    accessToken: data.access_token,
                    refreshToken: rf,
                  });
                  processQueue(null);
                  resolve(instance(originalRequest));
                } else {
                  throw new Error("access_token not found");
                }
              })
              .catch(err => {
                clearLoginData();
                processQueue(err);
                reject(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        } else {
          clearLoginData();
        }
      } else {
        // wrong login infomation
        return Promise.reject(error);
      }
    } else {
      // orther errors || server side request
      return Promise.reject(error);
    }
  }
);

function clearLoginData() {
  const cookie = new Cookies();
  cookie.remove("authToken", { ...baseCookieConfig });
  cookie.remove("refreshToken", { ...baseCookieConfig });
  localStorage.removeItem("ts_user");
  sendWebViewEvent("LogoutSuccess", {});
}

export default instance;
