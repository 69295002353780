import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_VIETNAMESE_LOCATIONS,
  CATEGORIES_GET_REQUEST,
  UNITS_GET_REQUEST,
  RECENT_PRODUCTS_GET_REQUEST,
} from "../constants/actionTypes";
import { addVietnameseLocations } from "../actions/common.action";
import { getVietnameseLocations } from "#Root/api/Location";
import {
  updateCommonState,
  recentProductsGetSuccess,
} from "../actions/common.action";
import { getCategories, getAllUnits } from "../../api/Categories";
import { getRecentProducts } from "../../api/Products";

function* _getVietnameseLocations() {
  try {
    const locations = yield call(getVietnameseLocations);
    yield put(addVietnameseLocations(locations));
  } catch (error) {}
}

export function* doGetCategories(action) {
  try {
    const categories = yield call(getCategories, `level=${action.level}`);
    yield put(updateCommonState(`categoriesLv${action.level}`, categories));
  } catch (error) {}
}

function* doGetUnits(action) {
  try {
    const units = yield call(getAllUnits);
    yield put(updateCommonState("units", units));
  } catch (error) {}
}

function* doGetRecentProducts(action) {
  try {
    const res = yield call(getRecentProducts, action.query);
    yield put(recentProductsGetSuccess(res));
  } catch (error) {
    console.log(error);
  }
}

function* commonSaga() {
  yield takeEvery(GET_VIETNAMESE_LOCATIONS, _getVietnameseLocations);
  yield takeEvery(CATEGORIES_GET_REQUEST, doGetCategories);
  yield takeEvery(UNITS_GET_REQUEST, doGetUnits);
  yield takeEvery(RECENT_PRODUCTS_GET_REQUEST, doGetRecentProducts);
}

export default commonSaga;
