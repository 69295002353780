import App from "next/app";
import Router from "next/router";

import React from "react";
import { updateCommonState } from "../redux/actions/common.action";

import NProgress from "nprogress";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "#Root/configs/themes";

import "../public/static/styles/index.scss";

import TagManager from "react-gtm-module";

import * as Sentry from "@sentry/browser";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import { updateUserState } from "../redux/actions/user.action";
import dynamic from "next/dynamic";
import "intersection-observer";
import "allsettled-polyfill";
import { wrapper } from "../redux/store";
import { addSeconds, isSameDay, differenceInSeconds, endOfDay } from "date-fns";
import mobileAppRouterIntercept from "../helpers/mobileAppRouterIntercept";

const DownloadAppModal = dynamic(() =>
  import("../components/common/DownloadAppModal")
);
const CenterBanner = dynamic(() => import("../components/common/CenterBanner"));
const ReferralModal = dynamic(
  () => import("../components/common/ReferralModal"),
  {
    ssr: false,
  }
);

NProgress.configure({ showSpinner: false });

Router.events.on("routeChangeStart", () => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    ignoreErrors: [
      // zalo webview
      "Can't find variable: zaloJSV2",
      "zaloJSV2 is not defined",
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Cancelled",
      "TypeError: đã hủy",
      "adsbygoogle.push() error",
      "ChunkLoadError",
      "Native screen matched",
    ],

    integrations: [Sentry.browserTracingIntegration()],

    tracesSampleRate: 0.01,
    tracePropagationTargets: ["localhost", /^https:\/\/api\.thitruongsi\.com/],
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,

    denyUrls: [
      // adsense
      /pagead2\.googlesyndication\.com/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}

const isDev = !(process.env.APP_ENV === "production");

const tagManagerArgs = {
  gtmId: "GTM-579SX86",
  auth: isDev ? "fRPGCVIsgRQIGzbbOEb7Gw" : "aefmPlwMvIEB6vB-BB2BQg",
  preview: isDev ? "env-23" : "env-1",
};

// export function reportWebVitals(metric) {
//   if (metric.label === "web-vital") {
//     gaGenericEvent(
//       "Web Vitals",
//       metric.name,
//       metric.id,
//       Math.round(metric.name === "CLS" ? metric.value * 1000 : metric.value)
//     );
//   }
// }
import TTSChatSDK from "../components/common/TTSChatSDK";
import { TTS_UTM_SOURCE_COOKIE } from "../configs/keys";
import isSEBot from "../helpers/seo/isSEBot";
import { baseCookieConfig } from "../helpers/storage/cookie";
import GoogleSignIn from "../components/common/GoogleSignIn";

const RateThisAppModal = dynamic(
  () => import("../components/common/RateThisAppModal"),
  { ssr: false }
);

class MyApp extends App {
  static getInitialProps = wrapper.getInitialAppProps(
    store =>
      async ({ Component, ctx }) => {
        const { res, req } = ctx;
        let isMobileApp = false;
        let mobileAppVersion = 0;
        let isSearchEngineBot = false;

        // detech search engine bot
        if (req) {
          isSearchEngineBot = isSEBot(req.headers["user-agent"]);
          if (isSearchEngineBot) {
            store.dispatch(updateCommonState("isSearchEngineBot", true));
          }
        }

        let cookies;
        if (req?.headers.cookie) {
          cookies = new Cookies(req.headers.cookie);
        }

        const token =
          ctx.req?.headers["token"] ||
          ctx.req?.headers["t-token"] ||
          ctx.req?.query["t-token"] ||
          cookies?.get("authToken");

        const refreshToken =
          ctx.req?.headers["refresh-token"] ||
          ctx.req?.headers["t-refresh-token"] ||
          ctx.req?.query["t-refresh-token"] ||
          cookies?.get("refreshToken");

        const device = ctx.req?.headers["device"] || ctx.req?.query["device"];
        const deviceOS = ctx.req?.headers["t-os"] || ctx.req?.query["t-os"];
        const mobileAppVersionHeader =
          ctx.req?.headers["app-version"] || ctx.req?.query["app-version"];

        let ref_code = "";

        const tDeviceOS = cookies ? cookies.get("t-device-os") : "";
        const mobileAppVersionCookie = cookies
          ? cookies.get("t-mobile-app-version")
          : "";

        if (req && token) {
          res.cookie("authToken", token, {
            ...baseCookieConfig,
            expires: new Date(Date.now() + 157784760000),
          });

          if (refreshToken) {
            res.cookie("refreshToken", refreshToken, {
              ...baseCookieConfig,
              expires: new Date(Date.now() + 157784760000),
            });
          }

          if (req.headers["token"] && device === "Mobile-App") {
            // old native app
            store.dispatch(updateCommonState("isWebview", true));
          }
        }

        if (tDeviceOS || device === "Mobile-App") {
          // new native app
          isMobileApp = true;
          mobileAppVersion =
            parseInt(mobileAppVersionHeader || mobileAppVersionCookie, 10) || 0;
          store.dispatch(updateCommonState("isMobileApp", isMobileApp));
          store.dispatch(
            updateCommonState("mobileAppVersion", mobileAppVersion)
          );
          store.dispatch(updateCommonState("deviceOS", tDeviceOS || deviceOS));
          if (!tDeviceOS && deviceOS) {
            res.cookie("t-device-os", deviceOS, {
              ...baseCookieConfig,
              maxAge: 1000 * 3600 * 24 * 365, // 365 days
            });
          }
          if (mobileAppVersion && !parseInt(mobileAppVersionCookie)) {
            res.cookie("t-mobile-app-version", mobileAppVersion, {
              ...baseCookieConfig,
              maxAge: 1000 * 3600 * 24 * 365, // 365 days
            });
          }
        }

        if (req && req.query.ref_code) {
          ref_code = req.query.ref_code;
          res.cookie("ref_code", ref_code, {
            ...baseCookieConfig,
            expires: new Date(new Date() + 7776000000), // 90 days
          });
        }

        if (cookies) {
          // const cookies = new Cookies(req.headers.cookie);
          const authToken = token || cookies.get("authToken");
          const rf = refreshToken || cookies.get("refreshToken");
          if (authToken) {
            try {
              const decoded = jwtDecode(authToken);
              const user_id = decoded.sub;
              const user_type = decoded.scope?.includes("shop")
                ? "seller"
                : "buyer";
              await store.dispatch(
                updateUserState("all", {
                  token: authToken,
                  refresh_token: rf,
                  user_id,
                  user_type,
                })
              );
            } catch (error) {}
          }
        }

        const pageProps = {
          ...(Component.getInitialProps
            ? await Component.getInitialProps(ctx)
            : {}),
        };
        // Stop the saga if on server
        // if (req) {
        //   store.dispatch(END);
        //   await store.sagaTask.toPromise();
        // }

        return {
          ref_code,
          pageProps,
          isMobileApp,
          mobileAppVersion,
          isSearchEngineBot,
        };
      }
  );

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    if (!this.props.isSearchEngineBot && !this.props.mobileAppVersion) {
      TagManager.initialize({
        ...tagManagerArgs,
        dataLayer: {
          ts_user: localStorage.ts_user,
          user_id: localStorage.ts_user,
        },
      });
    }

    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        navigator.serviceWorker.register("/sw.js").then(
          function (registration) {
            console.log(
              "Service Worker registration successful with scope: ",
              registration.scope
            );
          },
          function (err) {
            console.log("Service Worker registration failed: ", err);
          }
        );
      });
    }

    let source = document.referrer.includes("google") ? "googlese" : "";
    if (Router.query.utm_source) {
      source = Router.query.utm_source;
    }
    if (source) {
      sessionStorage.setItem("utm_source", source);

      const now = new Date();
      let maxAgeSeconds = 60 * 30;

      if (!isSameDay(addSeconds(now, maxAgeSeconds), now)) {
        maxAgeSeconds = differenceInSeconds(endOfDay(now), now);
      }

      const cookie = new Cookies();

      cookie.set(TTS_UTM_SOURCE_COOKIE, source, {
        ...baseCookieConfig,
        maxAge: maxAgeSeconds,
      });
    }

    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );

    if (this.props.isMobileApp) {
      mobileAppRouterIntercept(this.props.mobileAppVersion);
      const handleMessage = event => {
        if (event.data) {
          try {
            let parsedEvent = JSON.parse(event.data);
            if (parsedEvent.type === "scrollToTop") {
              window.scrollTo({ top: 0, left: 0, behavior: "auto" });
            }
            if (parsedEvent.type === "replaceRouter") {
              Router.replace(parsedEvent.data);
            }
          } catch (error) {}
        }
      };
      document.addEventListener("message", handleMessage);
      // for ios
      window.addEventListener("message", handleMessage);
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, ref_code, isSearchEngineBot, isMobileApp } =
      this.props;
    return (
      <ThemeProvider theme={theme}>
        <TTSChatSDK>
          <Component {...pageProps} isSearchEngineBot={isSearchEngineBot} />
        </TTSChatSDK>
        {!isSearchEngineBot && <CenterBanner />}
        {/* {!isSearchEngineBot && <DownloadAppModal />} */}
        {ref_code && <ReferralModal refCode={ref_code} />}
        {isMobileApp && <RateThisAppModal />}
        {!isMobileApp && !isSearchEngineBot && <GoogleSignIn />}
      </ThemeProvider>
    );
  }
}

export default wrapper.withRedux(MyApp);
