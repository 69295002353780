import {
  RECEIVE_CART,
  UPDATE_CART_DRAFT_ITEMS,
  SELECT_CART_ITEMS,
  DESELECT_CART_ITEMS,
  RECEIVE_SHOP_CART,
  REQUEST_CART,
} from "../constants/actionTypes";

export const requestCart = () => ({
  type: REQUEST_CART,
});

export const addCart = cart => ({
  type: RECEIVE_CART,
  cart,
});

export const updateDraftItems = items => ({
  type: UPDATE_CART_DRAFT_ITEMS,
  items,
});

export const selectCartItems = items => ({
  type: SELECT_CART_ITEMS,
  items,
});

export const deselectCartItems = variant_ids => ({
  type: DESELECT_CART_ITEMS,
  variant_ids,
});

export const receiveShopCart = (shopId, cart) => ({
  type: RECEIVE_SHOP_CART,
  shopId,
  cart,
});
