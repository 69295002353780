const botUserAgents = [
  "googlebot",
  "bingbot",
  "linkedinbot",
  "mediapartners-google",
  "chrome-lighthouse",
];

const isSEBot = userAgent => {
  if (!userAgent || typeof userAgent !== "string") {
    return false;
  }

  const userAgentLowercase = userAgent.toLowerCase();

  return botUserAgents.some(agent => userAgentLowercase.indexOf(agent) >= 0);
};

export default isSEBot;
