import axios from "../axios";
import qs from "query-string";

export const postReview = async review => {
  const { data } = await axios.post(
    `v1/review-platform/review-action.json`,
    review
  );
  return data.data;
};
export const getActionByShopID = async shop_id => {
  const { data } = await axios.get(`v1/review-platform/action/${shop_id}.json`);
  return data;
};
// export const getReviews = async action => {
//   const { data } = await axios.get(`v1/review-platform/review/${action}.json`);
//   return data;
// };
export const postAction = async action => {
  const { data } = await axios.post(
    `v1/review-platform/action-review.json`,
    action
  );
  return data.data;
};
export const getActionByActionReviewID = async action_id => {
  const { data } = await axios.get(
    `v1/review-platform/action/${action_id}.json`
  );
  return data;
};
export const getActions = async () => {
  const { data } = await axios.get(`v1/review-platform/action.json`);
  return data;
};
export const getActionByActionID = async userid => {
  const { data } = await axios.get(`v1/review-platform/review/${userid}.json`);
  return data;
};

//

export const getReviews = async query => {
  try {
    const queryString = qs.stringify({
      ...query,
      limit: query?.limit ?? 10,
      page: query?.page ?? 1,
    });
    const res = await axios.get(`/v1/review/reviews?${queryString}`);

    return { ...res.data, limit: query?.limit ?? 10, page: query?.page ?? 1 };
  } catch (error) {
    return {};
  }
};
