import { RECEIVE_TICKET } from "../constants/actionTypes";

const initialState = {
  tickets: [],
  ticket: {},
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_TICKET:
      return { ...state, ticket: action.ticket };
    default:
      return state;
  }
};

export default ticketsReducer;
