import { getPriceByQuantity } from "../../helpers/prices/getPriceByQuantity";
import { WebviewEvents, sendWebViewEvent } from "../../helpers/webview";

export const REVIEW_CART_STEP = 1;
export const COLLECT_SHIPPING_ADDRESS_STEP = 2;
export const COLLECT_SHIPPING_METHOD_STEP = 3;
export const COLLECT_PAYMENT_METHOD_STEP = 4;
export const CONFIRM_PURCHASE_STEP = 5;

export const gaGenericEvent = (
  eventcategory,
  eventaction,
  eventlabel,
  eventvalue
) => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: "ga_event",
    eventcategory,
    eventaction,
    eventlabel,
    eventvalue,
  });
};

export const registerSuccessEvent = () => {
  gaGenericEvent("register", "success", "web_mobile");
};

export const productClickEvent = (products, list = "") => {
  const dataLayer = getDataLayer();
  const eventObj = {
    event: "productClick",
    ecommerce: {
      click: {
        actionField: { list },
        products: products.map(product => ({
          name: product.title,
          id: product.id,
          price: _safeGetPrice(product.prices),
          category: _safeGetCategory(product.product_type),
        })),
      },
    },
  };

  if (!list) {
    delete eventObj.ecommerce.click.actionField;
  }

  dataLayer.push(eventObj);
};

//

export const productDetailImpressions = products => {
  const dataLayer = getDataLayer();

  dataLayer.push({
    event: "eec.detail",
    ecommerce: {
      detail: {
        products: products.map(_transformProduct),
      },
    },
  });
};

export const checkoutEvent = (step, option, cartItems = []) => {
  const dataLayer = getDataLayer();

  const checkoutObj = {
    actionField: { step, option },
    products: cartItems.map(item => ({
      ..._transformCartItem(item),
      price: item.price,
    })),
  };

  !checkoutObj.products.length && delete checkoutObj.products;

  dataLayer.push({
    event: "checkout",
    ecommerce: {
      checkout: checkoutObj,
    },
  });
};

//

export const checkoutOptionEvent = (step, checkoutOption) => {
  const dataLayer = getDataLayer();

  dataLayer.push({
    event: "checkoutOption",
    ecommerce: {
      checkout_option: {
        actionField: { step: step, option: checkoutOption },
      },
    },
  });
};

//
export const purchasedEvent = (order, transaction_id) => {
  const dataLayer = getDataLayer();

  const totalShippingPrice = order.shipping_lines.reduce(
    (acc, cur) => acc + cur.price,
    0
  );

  const actionField = {
    id: order.id,
    revenue: Number(order.subtotal_price),
    shipping: totalShippingPrice,
  };

  const products = order.line_items.map(item => ({
    name: item.title,
    id: item.product_id,
    price: item.price,
    quantity: item.quantity,
    variant: item.variant_title,
    category: _safeGetCategory(item.product_type),
  }));

  dataLayer.push({
    event: "eec.purchased",
    ecommerce: {
      purchase: {
        actionField,
        products,
      },
    },
  });
};

// PRIVATE HELPER

const getDataLayer = () => {
  const isClient = typeof window !== "undefined";
  const dataLayer = isClient ? window.dataLayer || [] : [];
  return dataLayer;
};

const _transformProduct = product => ({
  name: product.title,
  id: product.id,
  price: _safeGetPrice(product.prices),
  category: _safeGetCategory(product.product_type),
  content_category: product.product_type_slug,
});

const _safeGetPrice = prices => {
  try {
    return prices[0].price || 0;
  } catch (error) {
    return 0;
  }
};

const _safeGetCategory = product_type => {
  try {
    return product_type.split(" > ")[0] || "Uncategorized";
  } catch (error) {
    return "Uncategorized";
  }
};

export const isCheckoutExist = step => {
  const dataLayer = getDataLayer();

  return dataLayer.some(
    event =>
      event.event === "checkout" &&
      event.ecommerce &&
      event.ecommerce.checkout &&
      event.ecommerce.checkout.actionField &&
      event.ecommerce.checkout.actionField.step === step
  );
};

// GA4
const calcPriceValue = products => {
  return products.reduce((a, c) => a + c.prices[0]?.price ?? 0, 0);
};

const productToEventItem = (products, listName) => {
  return products.map(product => ({
    item_id: product.id,
    item_name: product.title,
    item_category: product.product_type?.split(" > ")[0],
    item_category2: product.product_type?.split(" > ")[1],
    item_list_id: listName,
    item_list_name: listName,
    price: product.prices?.[0]?.price ?? 0,
    quantity: 1,
  }));
};

const _transformCartItem = item => ({
  name: item.product_title,
  id: item.product_id,
  price: getPriceByQuantity(item.prices, item.quantity),
  category: _safeGetCategory(item.product_type),
  variant: item.variant_title,
  quantity: item.quantity,
});

export const viewItemEvent = (products, mobileAppVersion) => {
  const value = calcPriceValue(products);
  const items = productToEventItem(products);

  if (mobileAppVersion >= 3) {
    sendWebViewEvent(WebviewEvents.LogEvent, {
      name: "view_item",
      params: {
        currency: "VND",
        value,
        items,
      },
    });
  } else {
    const dataLayer = getDataLayer();
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "VND",
        value,
        items,
      },
    });
  }
};

export const addToCartEvent = (cartItems, mobileAppVersion) => {
  const value = cartItems.reduce(
    (a, c) => a + c.quantity * getPriceByQuantity(c.prices, c.quantity),
    0
  );
  const items = cartItems.map(cartItem => ({
    item_id: cartItem.product_id,
    item_name: cartItem.product_title,
    item_category: cartItem.product_type?.split(" > ")[0],
    item_category2: cartItem.product_type?.split(" > ")[1],
    item_variant: cartItem.variant_title,
  }));

  if (mobileAppVersion >= 3) {
    sendWebViewEvent(WebviewEvents.LogEvent, {
      name: "add_to_cart",
      params: {
        currency: "VND",
        value,
        items,
      },
    });
    sendWebViewEvent(WebviewEvents.LogEvent, {
      name: "view_cart",
      params: {
        currency: "VND",
        value,
        items,
      },
    });
  } else {
    const dataLayer = getDataLayer();
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "VND",
        value,
        items,
      },
    });

    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    dataLayer.push({
      event: "view_cart",
      ecommerce: {
        currency: "VND",
        value,
        items,
      },
    });
  }
};

export const viewCartEvent = cartItems => {
  const dataLayer = getDataLayer();
  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  const totalPrice = cartItems.reduce(
    (a, c) => a + c.quantity * getPriceByQuantity(c.prices, c.quantity),
    0
  );
  dataLayer.push({
    event: "view_cart",
    ecommerce: {
      currency: "VND",
      value: totalPrice,
      items: cartItems.map(cartItem => ({
        item_id: cartItem.product_id,
        item_name: cartItem.product_title,
        item_category: cartItem.product_type?.split(" > ")[0],
        item_category2: cartItem.product_type?.split(" > ")[1],
        item_variant: cartItem.variant_title,
      })),
    },
  });
};

export const searchEvent = searchTerm => {
  const dataLayer = getDataLayer();
  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: "search",
    ecommerce: {
      search_term: searchTerm,
    },
  });
};

export const shareEvent = (content_type, item_id, mobileAppVersion) => {
  if (mobileAppVersion >= 3) {
    sendWebViewEvent(WebviewEvents.LogEvent, {
      name: "share",
      params: {
        content_type,
        item_id,
      },
    });
  } else {
    const dataLayer = getDataLayer();
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "share",
      ecommerce: {
        content_type,
        item_id,
      },
    });
  }
};

export const removeFromCartEvent = (cartItems, mobileAppVersion = null) => {
  const totalPrice = cartItems.reduce((a, c) => a + c.final_line_price, 0);
  const items = cartItems.map(cartItem => ({
    item_id: cartItem.product_id,
    item_name: cartItem.product_title,
    item_category: cartItem.product_type.split(" > ")[0],
    item_category2: cartItem.product_type.split(" > ")[1],
    item_variant: cartItem.variant_title,
  }));

  if (mobileAppVersion >= 3) {
    sendWebViewEvent(WebviewEvents.LogEvent, {
      name: "remove_from_cart",
      params: {
        currency: "VND",
        value: totalPrice,
        items: items,
      },
    });
  } else {
    const dataLayer = getDataLayer();
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: "VND",
        value: totalPrice,
        items: items,
      },
    });
  }
};

export const purchaseEvent = (order, mobileAppVersion) => {
  const totalShippingPrice = order.shipping_lines.reduce(
    (acc, cur) => acc + cur.price,
    0
  );
  const items = order.line_items.map(lineItem => ({
    item_id: lineItem.product_id,
    item_name: lineItem.title,
    item_variant: lineItem.variant_title,
    price: Number(lineItem.price),
    quantity: lineItem.quantity,
  }));

  if (mobileAppVersion >= 3) {
    sendWebViewEvent(WebviewEvents.LogEvent, {
      name: "purchase",
      params: {
        currency: "VND",
        transaction_id: String(order.id),
        value: Number(order.total_price),
        shipping: totalShippingPrice,
        items: items,
      },
    });
  } else {
    const dataLayer = getDataLayer();
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "purchase",
      ecommerce: {
        currency: "VND",
        transaction_id: String(order.id),
        value: Number(order.total_price),
        shipping: totalShippingPrice,
        items: items,
      },
    });
  }
};

export const loginEvent = method => {
  const dataLayer = getDataLayer();

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: "login",
    ecommerce: {
      method,
    },
  });
};

export const beginCheckoutEvent = (lineItems, mobileAppVersion) => {
  const totalPrice = lineItems.reduce((a, c) => a + c.final_line_price, 0);
  const items = lineItems.map(lineItem => ({
    item_id: lineItem.product_id,
    item_name: lineItem.product_title,
    item_category: lineItem.product_type?.split(" > ")[0],
    item_category2: lineItem.product_type?.split(" > ")[1],
    item_variant: lineItem.variant_title,
    price: lineItem.final_line_price,
    quantity: lineItem.quantity,
  }));

  if (mobileAppVersion >= 3) {
    sendWebViewEvent(WebviewEvents.LogEvent, {
      name: "begin_checkout",
      params: {
        currency: "VND",
        value: totalPrice,
        items: items,
      },
    });
  } else {
    const dataLayer = getDataLayer();
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: "VND",
        value: totalPrice,
        items: items,
      },
    });
  }
};

export const addShippingInfoEvent = mobileAppVersion => {
  if (mobileAppVersion >= 3) {
    sendWebViewEvent(WebviewEvents.LogEvent, {
      name: "add_shipping_info",
    });
  } else {
    const dataLayer = getDataLayer();
    dataLayer.push({
      event: "add_shipping_info",
    });
  }
};

export const addPaymentInfoEvent = mobileAppVersion => {
  if (mobileAppVersion >= 3) {
    sendWebViewEvent(WebviewEvents.LogEvent, {
      name: "add_payment_info",
    });
  } else {
    const dataLayer = getDataLayer();
    dataLayer.push({
      event: "add_payment_info",
    });
  }
};

export const addToWishlistEvent = (product, mobileAppVersion) => {
  const items = [
    {
      item_id: product.id,
      item_name: product.title,
      item_category: product.product_type?.split(" > ")[0],
      item_category2: product.product_type?.split(" > ")[1],
    },
  ];

  if (mobileAppVersion >= 3) {
    sendWebViewEvent(WebviewEvents.LogEvent, {
      name: "add_to_wishlist",
      params: {
        items: items,
      },
    });
  } else {
    const dataLayer = getDataLayer();
    dataLayer.push({
      event: "add_to_wishlist",
      ecommerce: {
        items: items,
      },
    });
  }
};

export const logCustomEvent = (mobileAppVersion, event = { event: "" }) => {
  if (mobileAppVersion >= 3) {
    sendWebViewEvent(WebviewEvents.LogEvent, event);
  } else {
    const dataLayer = getDataLayer();
    dataLayer.push(event);
  }
};
