import { combineReducers } from "redux";

import common from "./common.reducer";
import products from "./products.reducer";
import orders from "./orders.reducer";
import tickets from "./tickets.reducer";
import user from "./user.reducer";
import cart from "./carts.reducer";
import customer from "./customers.reducer";
import notifications from "./notifications.reducer";
import homepage from "./homepage.reducer";
import wallet from "./wallet.reducer";
import { HYDRATE } from "next-redux-wrapper";

const combinedReducer = combineReducers({
  common,
  products,
  orders,
  tickets,
  user,
  customer,
  cart,
  notifications,
  homepage,
  wallet,
});

const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    if (state.count) nextState.count = state.count; // preserve count value on client side navigation
    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

export default rootReducer;
