import getHostName from "../../helpers/getHostName";

const GRAPHQL_URI = `${getHostName}${process.env.API_HOSTNAME}/graphql/`;

export async function makeGraphQLCall(query, variables) {
  const res = await fetch(GRAPHQL_URI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      //   Authorization: `Bearer ${API_TOKEN}`,
    },
    body: JSON.stringify({ query, variables }),
  });

  const data = await res.json();
  if (data.errors && data.errors[0]?.code === 404) {
    throw data.errors[0];
  }

  return data;
}

export async function fetchProductDetails(query, variables) {
  const res = await fetch(GRAPHQL_URI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      //   Authorization: `Bearer ${API_TOKEN}`,
    },
    body: JSON.stringify({ query, variables }),
  });

  const data = await res.json();

  if (data.errors && Array.isArray(data.errors)) {
    const is404 = data.errors.find(e => e.message === "Sản phẩm không tồn tại");

    if (is404) {
      throw is404;
    }
  }

  return data;
}
