import {
  SET_TRENDING_SEARCH_KEYWORDS,
  ADD_VIETNAMESE_LOCATION,
  UPDATE_COMMON_STATE,
  CATEGORIES_GET_REQUEST,
  UNITS_GET_REQUEST,
  ADD_RECENT_PRODUCTS,
  RECENT_PRODUCTS_GET_REQUEST,
  RECENT_PRODUCTS_GET_SUCCESS,
  GET_NOT_RATED_SHOPS,
} from "../constants/actionTypes";

export const setTrendingSearchKeywords = keywords => ({
  type: SET_TRENDING_SEARCH_KEYWORDS,
  keywords,
});

export const addVietnameseLocations = locations => ({
  type: ADD_VIETNAMESE_LOCATION,
  locations,
});

export const updateCommonState = (field, value) => ({
  type: UPDATE_COMMON_STATE,
  field,
  value,
});

export const categoryGetRequest = (level = 1) => ({
  type: CATEGORIES_GET_REQUEST,
  level,
});

export const unitsGetRequest = () => ({
  type: UNITS_GET_REQUEST,
});

export const addRecentProducts = product => ({
  type: ADD_RECENT_PRODUCTS,
  product,
});

export const recentProductsGetRequest = (query = {}) => ({
  type: RECENT_PRODUCTS_GET_REQUEST,
  query,
});

export const recentProductsGetSuccess = data => ({
  type: RECENT_PRODUCTS_GET_SUCCESS,
  data,
});

export const getNotRatedShops = data => ({
  type: GET_NOT_RATED_SHOPS,
  data,
});
