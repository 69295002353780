import axios from "../axios";

export const getNotifications = async query => {
  const { data } = await axios.get(`/v1/chat/api/alerts${query || ""}`);
  return data.data;
};

export const markAllAsSeen = async () => {
  const { data } = await axios.post(`/v1/chat/api/alerts/all_seen`);
  return data;
};
