import {
  HOME_PAGE_STATE_GET_REQUEST,
  HOME_PAGE_STATE_GET_SUCCESS,
} from "../constants/actionTypes";

export const homePageStateGetRequest = (isSeBot = false) => ({
  type: HOME_PAGE_STATE_GET_REQUEST,
  isSeBot,
});
export const homePageStateGetSuccess = state => ({
  type: HOME_PAGE_STATE_GET_SUCCESS,
  state,
});
