import {
  REQUEST_NOTIFICATIONS,
  RECEIVE_NOTIFICATIONS
} from "../constants/actionTypes";

const initialState = {};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_NOTIFICATIONS:
      return action.notifications;

    default:
      return state;
  }
};

export default notificationsReducer;
