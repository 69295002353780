import Cookies from "universal-cookie";

const dev = process.env.NODE_ENV !== "production";

export const baseCookieConfig = {
  domain: dev ? "localhost" : ".thitruongsi.com",
  path: "/",
};

export default new Cookies();
