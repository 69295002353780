import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCommonState } from "../redux/actions/common.action";
import mqtt from "mqtt";
import * as Sentry from "@sentry/browser";

import { refreshToken } from "../api/Auth";
import Cookies from "universal-cookie";
import { nanoid } from "nanoid";
import Emmitter from "../helpers/common/eventemitter3";
import { logCustomEvent } from "../api/Analytics/enhancedEcommerce";

const useChat = ({
  wsHost,
  userId,
  token,
  _refreshToken,
  endOnLeave = true,
  isAlreadyConnected = false,
}) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const [isConnected, setConnected] = useState(false);
  const mobileAppVersion = useSelector(({ common }) => common.mobileAppVersion);

  const mqttRef = useRef();

  useEffect(() => {
    if (!token || isAlreadyConnected) {
      return;
    }

    let refreshTokenRetry = 10;

    const clientId = nanoid();

    mqttRef.current = mqtt.connect(wsHost, {
      clientId: clientId,
      username: "jwt",
      password: token,
    });

    const messageTopic = "/sys/msg/users/" + userId;
    const eventsTopic = "/sys/events/users/" + userId;

    mqttRef.current.subscribe(eventsTopic, function (err) {});
    mqttRef.current.subscribe(messageTopic, function (err) {});

    mqttRef.current.on("connect", conack => {
      setConnected(true);
    });

    let prevMessage = {};

    mqttRef.current.on("message", function (topic, message) {
      let msg = JSON.parse(message.toString());
      switch (topic) {
        case messageTopic:
          if (msg?.messageId !== prevMessage?.messageId) {
            //  fix error duplicate msg
            setMessage(msg);
            dispatch(updateCommonState("newMessage", msg));
            Emmitter.emit("contact-message", msg);
          }
          prevMessage = msg;
          break;
        case eventsTopic:
          if (
            msg?.data !== userId &&
            ["contact-online", "contact-offline"].includes(msg?.event)
          ) {
            Emmitter.emit(msg.event, msg);
          }
          break;
        default:
          break;
      }
    });

    mqttRef.current.on("error", error => {
      console.log(error);
      if (
        error.message.includes("Bad username or password") &&
        refreshTokenRetry <= 10
      ) {
        refreshTokenRetry++;
        const cookies = new Cookies();
        const _refreshToken = cookies.get("refreshToken");
        if (_refreshToken) {
          refreshToken(_refreshToken)
            .then(data => {
              mqttRef.current.options.password = data.access_token;
              mqttRef.current.reconnect();
            })
            .catch(err => {
              mqttRef.current.end();
            });
        }
      } else {
        Sentry.captureException(error);
        mqttRef.current.end();
      }
    });

    const handleVisibilityChange = function () {
      if (document.visibilityState === "visible") {
        if (!mqttRef.current?.connected) {
          mqttRef.current.reconnect();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      mqttRef.current.end();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [token]);

  const sendMessage = (directMessage, callback) => {
    mqttRef.current.publish(
      "direct-message",
      JSON.stringify(directMessage),
      {},
      err => {
        err && Sentry.captureException(err);
        callback && callback(err);
      }
    );

    logCustomEvent(mobileAppVersion, { event: "send_message" });
  };

  const doConnectionCheck = () => {
    if (!mqttRef.current?.connected) {
      mqttRef.current?.reconnect();
    }
  };

  const readMessage = (data = { messageId: "", from: "" }) => {
    mqttRef.current.publish("read-message", JSON.stringify(data), 2, false);
  };

  return {
    message,
    sendMessage,
    readMessage,
    isConnected,
    doConnectionCheck,
  };
};

export default useChat;
