import axios from "../axios";

const getPromotedSlider = async () => {
  try {
    const { data } = await axios.get(
      `/uab/api/get?position=slide&platform=web_mobile`
    );
    return data.data[0].ads_content;
  } catch (error) {
    return [];
  }
};

export const getSecondaryBanner = async () => {
  try {
    const { data } = await axios.get(
      `/uab/api/get?platform=web_mobile&position=home_2`
    );
    return data.data[0].ads_content;
  } catch (error) {
    return [];
  }
};

export default getPromotedSlider;
