// COMMON SHARE TYPE

export const SET_TRENDING_SEARCH_KEYWORDS = "SET_TRENDING_SEARCH_KEYWORDS";
export const GET_VIETNAMESE_LOCATIONS = "GET_VIETNAMESE_LOCATIONS";
export const ADD_VIETNAMESE_LOCATION = "ADD_VIETNAMESE_LOCATION";
export const UPDATE_COMMON_STATE = "UPDATE_COMMON_STATE";
export const CATEGORIES_GET_REQUEST = "CATEGORIES_GET_REQUEST";
export const UNITS_GET_REQUEST = "UNITS_GET_REQUEST";
export const RECENT_PRODUCTS_GET_REQUEST = "RECENT_PRODUCTS_GET_REQUEST";
export const RECENT_PRODUCTS_GET_SUCCESS = "RECENT_PRODUCTS_GET_SUCCESS";
export const ADD_RECENT_PRODUCTS = "ADD_RECENT_PRODUCTS";
export const GET_NOT_RATED_SHOPS = "GET_NOT_RATED_SHOPS";

// PRODUCT ACTION

export const REQUEST_PRODUCT = "REQUEST_PRODUCT";
export const RECEIVE_PRODUCT = "RECEIVE_PRODUCT";
export const RECEIVE_PRODUCT_V1 = "RECEIVE_PRODUCT_V1";
export const PRODUCT_DETAIL_CLEANUP = "PRODUCT_DETAIL_CLEANUP";
export const PRODUCT_DETAIL_GET_REQUEST = "PRODUCT_DETAIL_GET_REQUEST";
export const PRODUCT_DETAIL_GET_SUCCESS = "PRODUCT_DETAIL_GET_SUCCESS";
export const UPDATE_PRODUCT_STATE = "UPDATE_PRODUCT_STATE";
export const PREPARE_PRODUCT_DETAIL_STATE = "PREPARE_PRODUCT_DETAIL_STATE";

// USER ACTION
export const ADD_USER_INFO = "ADD_USER_INFO";
export const UPDATE_REGISTER_DATA = "UPDATE_REGISTER_DATA";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_COMPLETE = "UPDATE_USER_COMPLETE";
export const UPDATE_USER_STATE = "UPDATE_USER_STATE";
export const UPDATE_RECOVER_STATE = "UPDATE_RECOVER_STATE";

// CART ACTION
export const REQUEST_CART = "REQUEST_CART";
export const RECEIVE_CART = "RECEIVE_CART";
export const UPDATE_CART_DRAFT_ITEMS = "UPDATE_CART_DRAFT_ITEMS";
export const SELECT_CART_ITEMS = "SELECT_CART_ITEMS";
export const DESELECT_CART_ITEMS = "DESELECT_CART_ITEMS";
export const CART_CLEAN = "CART_CLEAN";
export const RECEIVE_SHOP_CART = "RECEIVE_SHOP_CART";

// CUSTOMERS ACTION
export const REQUEST_CUSTOMER = "REQUEST_CUSTOMER";
export const RECEIVE_CUSTOMER = "RECEIVE_CUSTOMER";
export const UPDATE_CUSTOMER_ADDRESS = "UPDATE_CUSTOMER_ADDRESS";
export const SET_CUSTOMER_ADDRESS_DEFAULT = "SET_CUSTOMER_ADDRESS_DEFAULT";
export const ADD_NEW_CUSTOMER_ADDRESS = "ADD_NEW_CUSTOMER_ADDRESS";

// ORDER ACTION
export const NEW_ORDER_INITIALIZE = "NEW_ORDER_INITIALIZE";
export const EDIT_NEW_ORDER = "EDIT_NEW_ORDER";
export const RECEIVE_ORDER = "RECEIVE_ORDER";
export const EDIT_PROMOS = "EDIT_PROMOS";
export const SHIPPING_RATES_GET_REQUEST = "SHIPPING_RATES_GET_REQUEST";
export const SHIPPING_RATES_GET_SUCCESS = "SHIPPING_RATES_GET_SUCCESS";
export const NEW_ORDER_CLEANUP = "NEW_ORDER_CLEANUP";

// TICKETS ACTION
export const RECEIVE_TICKET = "RECEIVE_TICKET";

// NOTIFICATIONS ACTION
export const REQUEST_NOTIFICATIONS = "REQUEST_NOTIFICATIONS";
export const RECEIVE_NOTIFICATIONS = "RECEIVE_NOTIFICATIONS";

// HOMEPAGE ACTION
export const HOME_PAGE_STATE_GET_REQUEST = "HOME_PAGE_STATE_GET_REQUEST";
export const HOME_PAGE_STATE_GET_SUCCESS = "HOME_PAGE_STATE_GET_SUCCESS";

export const COIN_TRANSACTIONS_GET_SUCCESS = "COIN_TRANSACTIONS_GET_SUCCESS";
