import axios from "../axios";
import qs from "query-string";

export const getCart = async (query = {}) => {
  const queryString = qs.stringify(query);
  const { data } = await axios.get(`/v2/order/api/carts.json?${queryString}`);
  return data.carts || {};
};

export const updateCart = async (user_id, items, shop_id = null) => {
  try {
    const { data } = await axios.post(`/v2/order/api/carts/update.json`, {
      user_id,
      items,
      shop_id,
    });
    return data.cart;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};
