// only call client side
import NProgress from "nprogress";
import Router from "next/router";
import { sendWebViewEvent, WebviewEvents } from "./webview";
import { pathToRegexp } from "path-to-regexp";

var isBack = false;

const mobileAppRouterIntercept = (mobileAppVersion = 0) => {
  Router.events.on("routeChangeComplete", () => {
    sendWebViewEvent("URLChanged", window.location.href);
  });

  if (mobileAppVersion >= 2) {
    Router.beforePopState(state => {
      isBack = true;
      return true;
    });

    Router.events.on("routeChangeStart", href => {
      if (isBack) {
        // ignore back event
        return true;
      }

      const pathname = href?.split("?")[0];

      if (isMatchNativeScreen(pathname)) {
        NProgress.done();
        switch (true) {
          case homeRegexp.test(pathname):
            sendWebViewEvent(WebviewEvents.Navigate, [
              "Home",
              {
                url: `${
                  process.env.SITE_URL
                }${pathname}?t=${new Date().getTime()}`,
                initialUrl: "/",
              },
            ]);
            break;
          case productDetailRegexp.test(pathname):
            sendWebViewEvent(WebviewEvents.Push, [
              "ProductDetail",
              {
                url: `${process.env.SITE_URL}${pathname}`,
              },
            ]);
            break;

          case loginPageRegexp.test(pathname):
            sendWebViewEvent(WebviewEvents.OpenLoginModal);
            break;

          case registerPageRegexp.test(pathname):
            sendWebViewEvent(WebviewEvents.OpenLoginModal);
            break;

          default:
            sendWebViewEvent(WebviewEvents.OpenURL, `tts://${href}`);
            break;
        }

        throw new Error("Native screen matched");
      }
    });

    Router.events.on("routeChangeComplete", () => {
      isBack = false;
    });
  }
};

const homeRegexp = pathToRegexp("/");
const productDetailRegexp = pathToRegexp("/:slug-:id(\\d+).html");
const loginPageRegexp = pathToRegexp("/login");
const registerPageRegexp = pathToRegexp("/user/register");

const regexps = [
  homeRegexp,
  pathToRegexp("/tin-nhan"),
  pathToRegexp("/tin-nhan/u/:userId"),
  loginPageRegexp,
  productDetailRegexp,
  pathToRegexp("/feed"),
  pathToRegexp("/category/(parent|child)/:id(\\d+)-:slug.html"),
  pathToRegexp("/shop/:slug*"),
  pathToRegexp("/user"),
  pathToRegexp("/category"),
  registerPageRegexp,
];

const isMatchNativeScreen = pathname => {
  return regexps.some(re => re.test(pathname));
};

export default mobileAppRouterIntercept;
