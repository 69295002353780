export const ProductByIdQuery = `
query ProductById($id: String!) {
  ProductDetail(id: $id) {
    id
    id_number
    title
    handle
    status
    review_status
    is_deal
    body_html   
    warranty_period
    warranty_policy
    features    
    unit_name
    created_at
    shop_id
    product_type_slug
    product_type
    is_trade_assurance
    allow_purchase
    category_lv1
    category_lv2
    prices {
      id
      start_quantity
      end_quantity
      compare_at_price
      price          
    }
    coin_earn {
      min
      max
      promotion_text
    }

    shop {
      id
      created_at
      name
      total_view
      total_products
      review_state
      phone_number
      response_rate
      province {
        name
      }
      district {
        name
      }
      v3_categories {
        level            
      }
      owner {
        last_active
      }
      avatar
      is_vip
      is_verified
      slug
      have_better_prices
      mall
      basic
      leads
      accept_calls
      call_last_active
    }
    image {
      id
      src
    }
    images {
      id
      src
    }
    videos {
      id
      url
      thumb_url
    }
    variants {
      id
      title
      product_id
      product_title
      product_type
      inventory_quantity
      image_id
      shop_id
      prices {
        id
        start_quantity
        end_quantity
        price
      }                
      option1
      option2
    }

    options {
      id
      name
      display_name
      values
      position
      product_id
    }

    categories {
      lv1 {        
        id
        category_id
        title
        slug
      }

      lv2 {
        id
        category_id
        title
        slug
        total_product
      }
    }
    is_deal_active
    is_deal_v2
    deal_start_at
    deal_end_at
    deal_ordered
    deal_max_sale_quantity
    is_hot_deal
    discount_rate
    available
    showroom {
      title
      slug
    }
    quantity_per_unit
    is_classifieds
    classifieds {
      area
      business_phone_number
      button_type
      expired_at
      label
      website_url
    }
    hide_prices
    specifications {
      name
      value
    }
    size_chart {
      image_id
      src
    }
    leads
  }
}
`;

const ProductQuery = `
    id
    slug
    product_id
    shop_id
    product_type
    image_thumb
    compare_at_price
    title
    is_deal
    deal_state
    unit_name
    is_vip
    is_business
    is_classifieds
    price
    prices {
      compare_at_price
      price          
    }
    min_order_quantity
    discount
    stock
    have_better_prices
    quantity_per_unit
    is_classifieds
    classifieds {
      area
      business_phone_number
      button_type
      expired_at
      label
      website_url
    }
    created_at
    hide_prices
    shop_basic
`;
export const SearchProductsQuery = `
query searchProductsQuery($query: String!) {
  searchProducts(query: $query) {
    products {
      ${ProductQuery}
      shop {
        name
        city
        mall
      }
      total_inquiries
      total_likes
      leads
      rating_avg
    }
    ads {
      id
      impURL
      clickURL
      product {
        ${ProductQuery}
        shop {
          name
          city
          mall
        }
      }
    }
    total
  }
}
`;
