import { all, call, put, takeEvery } from "redux-saga/effects";
import { fetchProductDetails } from "../../../api/GraphQL";
import { ProductByIdQuery } from "../../../api/GraphQL/queries";
import { PRODUCT_DETAIL_GET_REQUEST } from "../../constants/actionTypes";
import {
  productDetailGetSuccess,
  updateProductState,
} from "../../actions/products.action";
import { getSearchSuggest, searchProducts } from "../../../api/Products";
import { getReviews } from "../../../api/Review";

function* runGetProductDetail(action) {
  try {
    const { data } = yield call(fetchProductDetails, ProductByIdQuery, {
      id: action.productId,
    });

    const productDetail = data.ProductDetail;
    yield put(
      productDetailGetSuccess({
        is404: false,
        productDetail: data.ProductDetail,
      })
    );

    // ssr for search engine bot
    if (action.isSeBot) {
      const [
        relatedProducts,
        relatedCate2Products,
        relatedSearchKeywords,
        reviewData,
      ] = yield all([
        call(
          searchProducts,
          `?keyword=${encodeURIComponent(
            productDetail.title
          )}&sort_by=_score&limit=6&exclude_product_ids=${
            productDetail.id
          }&filter_category_lv1=${productDetail.category_lv1}`,
          "graphql"
        ),
        call(
          searchProducts,
          `?filter_category_lv2=${productDetail.category_lv2}&limit=6`,
          "graphql"
        ),
        call(getSearchSuggest, {
          q: productDetail.title,
          limit: 9,
          match: "broad",
        }),
        call(getReviews, {
          product_id: productDetail.id,
        }),
      ]);

      yield put(updateProductState("relatedProducts", relatedProducts));
      yield put(
        updateProductState("relatedCate2Products", relatedCate2Products)
      );
      yield put(
        updateProductState("relatedSearchKeywords", relatedSearchKeywords)
      );
      yield put(updateProductState("reviewData", reviewData));
    }
  } catch (error) {
    console.log(error);
    if (error?.message === "Sản phẩm không tồn tại") {
      yield put(productDetailGetSuccess({ is404: true, productDetail: {} }));
    }
  }
}
function* productDetailSaga() {
  yield takeEvery(PRODUCT_DETAIL_GET_REQUEST, runGetProductDetail);
}

export default productDetailSaga;
