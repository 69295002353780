import { call, all, put, takeEvery } from "redux-saga/effects";
import { homePageStateGetSuccess } from "../../actions/homepage.action";
import { HOME_PAGE_STATE_GET_REQUEST } from "../../constants/actionTypes";
import { searchProducts } from "../../../api/Products";
import getPromotedSlider, {
  getSecondaryBanner,
} from "../../../api/PromotedSlider";
import { getShowroomListing } from "../../../api/Showroom";
import * as Sentry from "@sentry/browser";
import { doGetCategories } from "../common.saga";
import { getBlogPosts } from "../../../api/WPJson";

function* runGetHomePageState(action) {
  try {
    const callables = [
      call(
        searchProducts,
        "?filter_only_deal=true&filter_only_hot_deal=true&limit=6&sort_by=up_at",
        "graphql"
      ),
      call(
        searchProducts,
        "?sort_by=trending_score&limit=40&filter_min_stock=10",
        "graphql"
      ),
      call(getSecondaryBanner),
      call(getPromotedSlider),
      call(getShowroomListing),
    ];
    if (action.isSeBot) {
      callables.push(
        ...[
          call(
            searchProducts,
            "?filter_only_classifieds=true&limit=6&sort_by=up_at",
            "graphql"
          ),
          call(doGetCategories, { level: 1 }),
          call(doGetCategories, { level: 2 }),
          call(getBlogPosts, {
            categories: [774],
            per_page: 4,
            context: "embed",
            _embed: true,
          }),
        ]
      );
    }

    const [
      hotDealProducts,
      trendingProducts,
      secondaryBanners,
      primaryBanners,
      showrooms,
      classifieds = [],
      _categories1,
      _categories2,
      blogPosts = [],
    ] = yield all(callables);

    yield put(
      homePageStateGetSuccess({
        hotDealProducts,
        trendingProducts,
        // tradeAssuranceProducts,
        primaryBanners,
        secondaryBanners,
        showrooms,
        classifieds,
        blogPosts,
      })
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}
function* homePageSaga() {
  yield takeEvery(HOME_PAGE_STATE_GET_REQUEST, runGetHomePageState);
}
export default homePageSaga;
