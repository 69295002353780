import {
  ADD_USER_INFO,
  UPDATE_REGISTER_DATA,
  UPDATE_USER_INFO,
  UPDATE_USER_COMPLETE,
  UPDATE_USER_STATE,
} from "../constants/actionTypes";

const initialState = {
  token: "",
  user_id: "",
  user_type: "",
  user: {},
  editingUser: {},
  register: {
    province_id: "",
    district_id: "",
    account_type: "buyer",
    session_info_token: null,
  },
  recover: {
    phone: "",
    email: "",
    code: "",
    session_info_token: "",
  },
  shop: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_INFO:
      return {
        ...state,
        user: action.user,
        shop: action.shop,
      };
    case UPDATE_REGISTER_DATA:
      return {
        ...state,
        register: { ...state.register, [action.field]: action.value },
      };

    case UPDATE_USER_INFO:
      if (action.field === "all") {
        return { ...state, editingUser: action.value };
      }
      if (action.field === "clean") {
        return { ...state, editingUser: {} };
      }
      return {
        ...state,
        editingUser: { ...state.editingUser, [action.field]: action.value },
      };

    case UPDATE_USER_COMPLETE:
      return { ...state, user: { ...state.user, ...state.editingUser } };

    case UPDATE_USER_STATE:
      if (action.field === "all") {
        return { ...state, ...action.value };
      }
      return { ...state, [action.field]: action.value };

    default:
      return state;
  }
};

export default userReducer;
