import { call, put, takeEvery, select } from "redux-saga/effects";

import { REQUEST_CART } from "../constants/actionTypes";
import { getCart } from "#Root/api/Cart";
import { addCart } from "#Root/redux/actions/carts.action";

import cookie from "../../helpers/storage/cookie";

function* _getCart() {
  try {
    if (typeof window !== "undefined") {
      const authToken = cookie.get("authToken");
      if (authToken) {
        const cart = yield call(getCart);

        yield put(addCart(cart || {}));
      }
    }
  } catch (error) {}
}

function* cartSaga() {
  yield takeEvery(REQUEST_CART, _getCart);
}

export default cartSaga;
