import {
  HOME_PAGE_STATE_GET_REQUEST,
  HOME_PAGE_STATE_GET_SUCCESS,
} from "../constants/actionTypes";
const initialState = {
  isHomePageLoading: false,
  hotDealProducts: {},
  trendingProducts: {},
  tradeAssuranceProducts: {},
  primaryBanners: [],
  secondaryBanners: [],
  showrooms: {},
  classifieds: [],
  blogPosts: [],
};
const homePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_PAGE_STATE_GET_REQUEST: {
      return { ...state, isHomePageLoading: true };
    }
    case HOME_PAGE_STATE_GET_SUCCESS:
      return { ...state, ...action.state, isHomePageLoading: false };

    default:
      return state;
  }
};

export default homePageReducer;
