import {
  SET_TRENDING_SEARCH_KEYWORDS,
  ADD_VIETNAMESE_LOCATION,
  UPDATE_COMMON_STATE,
  ADD_RECENT_PRODUCTS,
  RECENT_PRODUCTS_GET_SUCCESS,
  GET_NOT_RATED_SHOPS,
} from "../constants/actionTypes";

const initialState = {
  trendingKeywords: [],
  vnLocations: [],
  centerBanner: null,
  isWebview: false,
  isMobileApp: false,
  isSearchEngineBot: false,
  isRateAppOpen: false,
  deviceOS: "",
  unreadTopics: 0,
  newMessage: {},
  searchKeyword: "",
  suggestSearchKeywords: [],
  categoriesLv1: [],
  categoriesLv2: [],
  units: [],
  recentProducts: {
    products: [],
  },
  notRatedShops: [],
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRENDING_SEARCH_KEYWORDS:
      return { ...state, trendingKeywords: action.keywords };

    case ADD_VIETNAMESE_LOCATION:
      return { ...state, vnLocations: action.locations };

    case RECENT_PRODUCTS_GET_SUCCESS:
      return { ...state, recentProducts: action.data };

    case ADD_RECENT_PRODUCTS:
      return {
        ...state,
        recentProducts: {
          ...state.recentProducts,
          products: [action.product, ...state.recentProducts.products].slice(
            0,
            6
          ),
        },
      };

    case UPDATE_COMMON_STATE:
      return { ...state, [action.field]: action.value };

    case GET_NOT_RATED_SHOPS:
      return { ...state, notRatedShops: action.data };

    default:
      return state;
  }
};

export default commonReducer;
