import axios from "../axios";
import defaultAxios from "axios";
import getOr from "lodash/fp/getOr";
import { makeGraphQLCall } from "../../api/GraphQL";
import { SearchProductsQuery } from "../../api/GraphQL/queries";
import qs from "query-string";

export const getDPAProducts = async query => {
  const { data } = await axios.get(`/v1/marketing/dpa${query}`);
  return data;
};

export const searchProducts = async (query, type = "rest") => {
  try {
    if (type === "graphql") {
      const res = await makeGraphQLCall(SearchProductsQuery, { query });
      return getOr(
        { total: 0, products: [], ads: [] },
        "data.searchProducts",
        res
      );
    }

    const { data } = await axios.get(
      `/v2/search/products${query ? query : ""}`
    );
    return data;
  } catch (error) {
    return { total: 0, products: [], ads: [] };
  }
};

export const searchProductsAMP = async query => {
  try {
    const { data } = await defaultAxios.get(
      process.env.SITE_URL +
        process.env.API_HOSTNAME +
        `/graphql/products${query ? query : ""}`
    );
    return data.data.searchProducts;
  } catch (error) {
    return { total: 0, products: [] };
  }
};

export const getSearchSuggest = async queryObj => {
  // const { data } = await axios.get(
  //   `/v1/analytics/api/suggest/keyword?q=${encodeURIComponent(keyword)}&limit=5`
  // );
  try {
    const { data } = await axios.get(
      `/v2/search/suggestion/keyword?${qs.stringify(queryObj)}`
    );
    return data?.keywords ?? [];
  } catch (error) {
    return [];
  }
};

export const getProductV1 = async product_id => {
  const { data } = await axios.get(`/v2/product/products/${product_id}`);
  return data.product;
};

export const getProducts = async query => {
  try {
    const { data } = await axios.get(
      `/v2/order/api/products.json${query ? query : ""}`
    );
    return data.products;
  } catch (error) {
    throw error;
  }
};

export const getProduct = async product_id => {
  try {
    const { data } = await axios.get(
      `/v2/order/api/products/${product_id}.json`
    );
    return data.product;
  } catch (error) {
    throw error;
  }
};

export const requestProductTicket = async ticket => {
  try {
    const { data } = await axios.post(`/v1/reqticket/api/tickets`, ticket);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRecentProducts = async (query = {}) => {
  const queryString = qs.stringify(query);
  const { data } = await axios.get(
    `/v1/analytics/user/recent_products${queryString ? `?${queryString}` : ""}`
  );
  return data;
};

export const addWishlist = async product_id => {
  const { data } = await axios.post(`/v1/marketing/wishlists`, {
    product_id,
  });

  return data;
};

export const removeWishlist = async product_id => {
  const { data } = await axios.delete(`/v1/marketing/wishlists/${product_id}`, {
    product_id,
  });

  return data;
};

export const isAddedWishlist = async product_id => {
  const { data } = await axios.get(
    `/v1/marketing/wishlists/${product_id}/is_added`,
    {
      product_id,
    }
  );

  return data;
};
export const getWishlistProducts = async (query = {}) => {
  const queryString = qs.stringify(query);
  const { data } = await axios.get(
    `/v1/marketing/wishlists/products${queryString ? `?${queryString}` : ""}`
  );
  return data;
};

export const doSubcribe = async ({ ref_feature = "", keyword = "" }) => {
  const { data } = await axios.post(`/v1/marketing/subscribes`, {
    ref_feature,
    keyword,
  });
  return data;
};

export const getRecommendedProductsForUser = async (query = {}) => {
  const queryString = qs.stringify(query);
  return axios.get(
    `/v1/analytics/user/recommend_products${
      queryString ? `?${queryString}` : ""
    }`
  );
};

export const getShopPriceRules = async (
  query = {
    shop_id: "",
    prerequisite_subtotal_price: "",
    sort_by: "",
    order: "",
    target_type: "",
  }
) => {
  const queryString = qs.stringify(query);
  const res = await axios.get(`/v2/order/api/price_rules.json?${queryString}`);
  return res.data.price_rules;
};
