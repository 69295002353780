import defaultAxios from "axios";
import axios from "../axios";

import cookie, { baseCookieConfig } from "../../helpers/storage/cookie";
import { sendWebViewEvent } from "../../helpers/webview";
import { loginEvent } from "../Analytics/enhancedEcommerce";

export const setAuthData = ({
  authToken = "",
  refreshToken = "",
  userId = "",
}) => {
  if (refreshToken) {
    cookie.set("refreshToken", refreshToken, {
      ...baseCookieConfig,
      expires: new Date(Date.now() + 157784760000),
    });
  }

  cookie.set("authToken", authToken, {
    ...baseCookieConfig,
    expires: new Date(Date.now() + 157784760000),
  });
  localStorage.ts_user = userId;
};

export const Login = async (email, password, src = "/") => {
  try {
    const { data } = await defaultAxios.post(
      process.env.API_HOSTNAME + `/v1/user/api/auth`,
      { email, password }
    );
    setAuthData({
      authToken: data.data.access_token,
      refreshToken: data.data.refresh_token,
      userId: data.data.sub,
    });
    // cookie.set("refreshToken", data.data.refresh_token, {
    //   path: "/",
    //   // 5 years expries time
    //   expires: new Date(Date.now() + 157784760000),
    // });
    // cookie.set("authToken", data.data.access_token, {
    //   path: "/",
    //   expires: new Date(Date.now() + 157784760000),
    // });
    // localStorage.ts_user = data.data.sub;
    sendWebViewEvent("AuthChange", {
      accessToken: data.data.access_token,
      refreshToken: data.data.refresh_token,
      nextPath: src,
    });

    // enhanced ecommerce
    loginEvent("tts");

    window.location.replace(src);
  } catch (error) {
    throw error;
  }
};

export const Logout = (src = null) => {
  cookie.remove("authToken", { ...baseCookieConfig });
  cookie.remove("refreshToken", { ...baseCookieConfig });
  localStorage.removeItem("ts_user");
  // sendWebViewEvent("AuthChange", {
  //   accessToken: "",
  //   refreshToken: "",
  // });
  sendWebViewEvent("LogoutSuccess", {});
  if (src) {
    window.location = src;
  } else {
    window.location.reload();
  }
};

export const getLoggedInUserInfo = async (token = null) => {
  try {
    if (token) {
      // server side request

      const { data } = await axios.get(`/v1/user/api/auth/info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        user: data.data.user,
        shop: data.data.shop,
      };
    } else {
      const { data } = await axios.get(`/v1/user/api/auth/info`);

      return {
        user: data.data.user,
        shop: data.data.shop,
      };
    }
  } catch (error) {
    return { user: {}, shop: {} };
  }
};

export const getUserByRefCode = async refCode => {
  try {
    const { data } = await axios.get(`/v1/user/api/user-by-ref/${refCode}`);
    return data.data.user;
  } catch (error) {
    return {};
  }
};

export const updateUserInfo = async userInfo => {
  const { data } = await axios.put(`/v1/user/api/v4/users/me`, userInfo);

  return data;
};

export const updateUserAccountPhone = async (
  params = { phone: "", otp: "" }
) => {
  return axios.put(`/v1/user/api/v4/users/account-phone`, params);
};

export const refreshToken = async refresh_token => {
  const { data } = await defaultAxios.get(
    process.env.API_HOSTNAME +
      `/v1/user/api/token/refresh?refresh_token=${refresh_token}`
  );
  return data.data;
};

export const requestToDeleteAccount = async () => {
  const { data } = await axios.post(`/v1/user/api/me/delete`);
  return data;
};

export const googleLoginWithIdToken = idToken => {
  return axios.post(
    `${process.env.OAUTH_URI}/oauth/google/idtokenlogin`,
    {
      driver: "google",
      id_token: idToken,
    },
    {
      withCredentials: true,
    }
  );
};
