import {
  RECEIVE_CART,
  UPDATE_CART_DRAFT_ITEMS,
  SELECT_CART_ITEMS,
  DESELECT_CART_ITEMS,
  CART_CLEAN,
  RECEIVE_SHOP_CART,
} from "../constants/actionTypes";
import transformCartToInsiderBasket from "../../helpers/common/transformCartToInsiderBasket";

const initialState = {
  currentCart: {},
  draftCartItems: [],
  cartSelectedItems: {},
  byShop: {},
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_CART:
      const selected_variant_ids = Object.keys(state.cartSelectedItems);
      if (selected_variant_ids.length) {
        const newCartSelectedItems = { ...state.cartSelectedItems };
        selected_variant_ids.forEach(variant_id => {
          const cartItem = action.cart.items.filter(
            item => item.variant_id === variant_id
          )[0];

          if (!cartItem) {
            delete newCartSelectedItems[variant_id];
            return;
          }
          newCartSelectedItems[variant_id] = cartItem;
        });
        return {
          ...state,
          currentCart: action.cart,
          cartSelectedItems: newCartSelectedItems,
        };
      }

      return { ...state, currentCart: action.cart };

    case UPDATE_CART_DRAFT_ITEMS:
      return { ...state, draftCartItems: action.items };

    case SELECT_CART_ITEMS:
      const shop_id = action.items[Object.keys(action.items)[0]].shop_id;
      const cartSelectedItems = { ...state.cartSelectedItems };

      for (const key of Object.keys(cartSelectedItems)) {
        if (cartSelectedItems[key].shop_id !== shop_id) {
          delete cartSelectedItems[key];
        }
      }

      return {
        ...state,
        cartSelectedItems: { ...cartSelectedItems, ...action.items },
      };

    case DESELECT_CART_ITEMS:
      let _cartSelectedItems = { ...state.cartSelectedItems };

      action.variant_ids.forEach(variant_id => {
        delete _cartSelectedItems[variant_id];
      });

      return {
        ...state,
        cartSelectedItems: _cartSelectedItems,
      };

    case RECEIVE_SHOP_CART:
      return {
        ...state,
        byShop: {
          ...state.byShop,
          [action.shopId]: action.cart,
        },
      };

    case CART_CLEAN:
      return { ...state, draftCartItems: [], cartSelectedItems: {} };

    default:
      return state;
  }
};

export default cartReducer;
