import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Button from "@material-ui/core/Button";
import Router, {useRouter} from "next/router";
import useChat from "../../hooks/useChat";
import axios from "../../api/axios";
import get from "lodash/fp/get";
import { updateCommonState } from "../../redux/actions/common.action";
export const ChatContext = React.createContext({});
const CHAT_WS_HOST =
  process.env.APP_ENV === "production"
    ? "wss://api.thitruongsi.com/v1/chat/"
    : "wss://api-sandbox.thitruongsi.com/v1/chat/";

const TTSChatSDK = props => {
  const token = useSelector(({ user }) => user.token);
  const userId = useSelector(({ user }) => user.user_id);
  const isMobileApp = useSelector(({ common }) => common.isMobileApp);
  const mobileAppVersion = useSelector(({ common }) => common.mobileAppVersion);
  const { message, sendMessage, readMessage, isConnected, doConnectionCheck } =
    useChat({
      wsHost: CHAT_WS_HOST,
      token: token,
      userId: userId,
      // disable mqtt
      isAlreadyConnected: mobileAppVersion >= 2,
    });
  return (
    <ChatContext.Provider
      value={{
        message,
        sendMessage,
        readMessage,
        isConnected,
        doConnectionCheck,
      }}
    >
      {props.children}
      {!isMobileApp && <NewMessageAlert />}
    </ChatContext.Provider>
  );
};

const NewMessageAlert = props => {
  const router = useRouter();
  const dispatch = useDispatch();
  const userId = useSelector(({ user }) => user.user_id);
  const unreadTopics = useSelector(({ common }) => common.unreadTopics);
  const newMessage = useSelector(({ common }) => common.newMessage);
  const [isCancelled, setCancelled] = useState(true);

  useEffect(() => {
    if (userId) {
      axios.get(`/v1/chat/api/topics/unread`).then(resp => {
        const unreadTopics = get("data.unread_topics", resp);
        dispatch(updateCommonState("unreadTopics", unreadTopics));
      });
    }
  }, []);

  useEffect(() => {
    if (router.pathname != "/") {
      return;
    }

    if (newMessage.messageId || unreadTopics > 0) {
      setCancelled(false);
    }
  }, [newMessage, unreadTopics, router.pathname]);

  const handleCancel = useCallback(() => setCancelled(true), []);
  const handleRead = useCallback(() => {
    Router.push("/tin-nhan");
    setCancelled(true);
  }, []);

  if (isCancelled) {
    return null;
  }

  return (
    <>
      <div className="new-message-alert">
        <div className="flex">
          <NotificationsActiveIcon color="primary" />
          <div className="ml-3">Bạn có tin nhắn mới</div>
        </div>
        <div className="flex justify-end items-center mt-3">
          <Button
            color="primary"
            onClick={handleCancel}
            style={{
              textTransform: "initial",
              marginRight: 10,
              fontWeight: 600,
            }}
          >
            Để sau
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            style={{ textTransform: "initial", color: "#fff" }}
            onClick={handleRead}
          >
            Xem ngay
          </Button>
        </div>
      </div>
      <style jsx>{`
        .new-message-alert {
          position: fixed;
          bottom: calc(3.2rem + env(safe-area-inset-bottom));
          left: 0;
          right: 0;
          background-color: #fff;
          padding: 20px 10px;
          animation: slideUp 0.2s;
          border-top: 1px solid #e8e8e8;
          border-bottom: 1px solid #e8e8e8;
          z-index: 2000;
        }
        @keyframes slideUp {
          0% {
            bottom: 0;
            opacity: 0.5;
          }

          100% {
            opacity: 1;
            bottom: 3rem;
          }
        }
      `}</style>
    </>
  );
};

export default TTSChatSDK;
