import { createTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f58220",
      contrastText: "#fff",
      ttsColor: "#f58220",
    },
    secondary: {
      main: "#19857b",
    },
    gray: {
      main: "gray",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  zIndex: {
    modal: 1300,
  },
});

export default theme;
