import axios from "../axios";

export const getVietnameseLocations = async () => {
  const { data } = await axios.get("/v1/user/api/vi/locations.json");
  return data.locations;
};

export const getProvinces = async () => {
  const { data } = await axios.get(`/v1/user/api/provinces`);
  return data.data.items;
};

export const getDistricts = async province_id => {
  const { data } = await axios.get(
    `/v1/user/api/provinces/${province_id}/districts`
  );
  return data.data.items;
};

export const getWards = async district_id => {
  const { data } = await axios.get(
    `/v1/user/api/districts/${district_id}/wards`
  );

  return data.data.items;
};
