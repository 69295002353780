import {
  ADD_USER_INFO,
  UPDATE_REGISTER_DATA,
  UPDATE_USER_INFO,
  UPDATE_USER_STATE,
} from "../constants/actionTypes";

export const addUserInfo = (user, shop) => ({
  type: ADD_USER_INFO,
  user,
  shop,
});

export const updateRegisterData = (field, value) => ({
  type: UPDATE_REGISTER_DATA,
  field,
  value,
});

export const updateUserInfo = (field, value) => ({
  type: UPDATE_USER_INFO,
  field,
  value,
});

export const updateUserState = (field, value) => ({
  type: UPDATE_USER_STATE,
  field,
  value,
});
