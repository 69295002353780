import { call, put, takeEvery, select } from "redux-saga/effects";

import { REQUEST_NOTIFICATIONS } from "../constants/actionTypes";
import { getNotifications } from "#Root/api/Notifications";
import { addNotifications } from "#Root/redux/actions/notifications.action";

function* _getNotifications(action) {
  try {
    const { query } = action;
    const currentNotifications = yield select(
      ({ notifications }) => notifications
    );

    // if (Object.keys(currentNotifications).length) {
    //   return;
    // }

    const notifications = yield call(getNotifications, query);
    yield put(addNotifications(notifications));
  } catch (error) {
    console.log(error);
  }
}

function* cartSaga() {
  yield takeEvery(REQUEST_NOTIFICATIONS, _getNotifications);
}

export default cartSaga;
