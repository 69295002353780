import axios from "../axios";
import qs from "query-string";

export const getBlogPosts = async (query = {}) => {
  const queryString = qs.stringify(query);
  const { data } = await axios.get(
    `https://thitruongsi.com/kinh-nghiem/wp-json/wp/v2/posts/?${queryString}`
  );

  return data;
};
