import axios from "../axios";

export const getCategories = async query => {
  const { data } = await axios.get(
    `/v2/product/categories.json${query ? `?${query}` : ""}`
  );
  return data.categories;
};

export const getCategoryInfo = async category_id => {
  const { data } = await axios.get(`/v2/product/categories/${category_id}`);
  return data;
};

export const getCategorySEO = async category_id => {
  const { data } = await axios.get(`/v1/marketing/seo/category/${category_id}`);
  return data.data;
};

export const getAllUnits = async () => {
  const { data } = await axios.get(`/v2/product/units.json`);
  return data.units;
};
