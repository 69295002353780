import { all, fork } from "redux-saga/effects";
import es6promise from "es6-promise";
import "isomorphic-unfetch";

import cart from "./cart.saga";
import notifications from "./notifications.saga";
import common from "./common.saga";
import productDetail from "./business/product-detail.saga";
import homepageSaga from "./business/homepage.saga";

es6promise.polyfill();

function* rootSaga() {
  yield all([
    fork(cart),
    fork(common),
    fork(notifications),
    fork(productDetail),
    fork(homepageSaga),
  ]);
}

export default rootSaga;
