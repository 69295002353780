import {
  REQUEST_NOTIFICATIONS,
  RECEIVE_NOTIFICATIONS
} from "../constants/actionTypes";

export const addNotifications = notifications => ({
  type: RECEIVE_NOTIFICATIONS,
  notifications
});

export const requestNotifications = query => ({
  type: REQUEST_NOTIFICATIONS,
  query
});
